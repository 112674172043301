
	import { Component, Vue } from "vue-property-decorator";
	import OrderPane from "./components/order-pane.vue";
	import DatePicker from "@/components/date-picker.vue";
	import { apiOrderLists, apiOtherLists } from "@/api/visa/order";
	import ExportData from "@/components/export-data/index.vue";
	import { RequestPaging } from "@/utils/util";
	import { KinmenType } from "@/utils/type";
	@Component({
		components: {
			OrderPane,
			DatePicker,
			ExportData,
		},
	})
	export default class Order extends Vue {
		// S Data
		activeName : any = 'all_count' //全部;
		activeStatus : any = ''

		apiOrderLists = apiOrderLists;

		tabs = [
			{
				label: '全部',
				name: KinmenType[2]
			},
			{
				label: '待提交',
				name: KinmenType[4]
			},
			{
				label: '待审核',
				name: KinmenType[5]
			},
			{
				label: '待送签',
				name: KinmenType[7]
			},
			{
				label: '已完成',
				name: KinmenType[9]
			},
			{
				label: '审核拒绝',
				name: KinmenType[1]
			},
			{
				label: '送签失败',
				name: KinmenType[0]
			}
		];

		index = 0;

		pager = new RequestPaging();

		tabCount = {
			all_count: 0, //全部
			STATUS_PAY_SUS: 0, //待提交
			STATUS_SUBMIT_TRUE: 0, //待审核
			STATUS_YMS_WAITING: 0, //待送签
			STATUS_COMPLETED: 0, //已完成
			STATUS_AUDIT_NO: 0, //审核拒绝
			STATUS_YMS_NO: 0, //送签失败
		}

		form = {
			order_no: '', //否	string	订单信息
			phone: '', //否	string	用户信息
			type: '',
			status: '',
			pay_status: '', //否	int	支付状态;0-待支付;1-已支付;
			time_type: '', //否	string	时间类型:create_time-下单时间;pay_time-支付时间
			start_time: '', //否	string	开始时间
			end_time: '' //否	string	结束时间
		}

		otherLists : any = {
			order_type_lists: [], //订单类型
			pay_status_lists: [], //支付状态
			status_lists: [], //支付状态
		}
		// E Data

		// S Methods

		// 获取订单信息
		getOrderLists(page ?: number) {
			page && (this.pager.page = page)
			if (this.form.start_time != '' || this.form.end_time != '') {
				if (this.form.time_type == '') {
					return this.$message.error('选择时间必须选择时间类型!')
				}
			}
			const status = this.activeName == 'all_count' ? '' : KinmenType[this.activeName];

			(this.activeStatus = status),
				this.pager
					.request({
						callback: apiOrderLists,
						params: {
							chose_status: status,
							...this.form
						}
					})
					.then(res => {
						this.tabCount = res.extend
					})
		}

		// 重置搜索
		reset() {
			Object.keys(this.form).map(key => {
				this.$set(this.form, key, '')
			})
			this.getOrderLists()
		}

		// 新增订单
		addOrder() {
			this.$router.push({
				path: '/visa/add',
			})
		}
		// 获取订单其他 方式数据
		getOtherMethodList() {
			apiOtherLists().then((res : any) => {
				this.otherLists = res;
			});
		}
		// E Methods

		// S  life cycle

		created() {
			// 获取订单信息
			this.getOrderLists();
			// 获取其他方式数据
			this.getOtherMethodList();
		}

		// E life cycle
	}
